import { useEffect, useRef, useState } from "react";
import "./MainContainer.css";
import { motion, useScroll } from "framer-motion";
import BenefitsComponent from "components/BenefitsComponent";
import QuestionComponent from "components/QuestionComponent";
import { ReactComponent as Laurel } from "assets/laurel_wreath.svg";
import { ReactComponent as Star } from "assets/star.svg";
import PriceComponent from "components/PriceComponent";
import StartNowComponent from "components/StartNowComponent";
import PortfolioComponent from "components/PortfolioComponent";
import ProcessComponent from "components/ProcessComponent";

const MainContainer = () => {
  const [isNeedDim, setIsNeedDim] = useState(true);
  const videoRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: videoRef,
    offset: ["2 0", "0 0"],
  });
  useEffect(() => {
    if (window.scrollY !== 0) {
      setIsNeedDim(false);
    }
  }, []);

  return (
    <>
      <div className="MainContainer">
        <div className={isNeedDim ? "dim" : ""} />
        <div className="introduce">
          <div className="introduceContent">
            <div className="delay0">
              <div className="laurelTopText">
                홈페이지 <br />
                제작 전문 업체
              </div>
              <div className="laurel">
                <Laurel className="laurelSvg" width="160px" />
                <div className="stars">
                  <Star width="16px" />
                  <Star width="16px" />
                  <Star width="16px" />
                  <Star width="16px" />
                  <Star width="16px" />
                </div>
                <div className="laurelBottomText">SINCE 2024</div>
              </div>
            </div>
            <div className="introduceTitle">
              <span className="delay1">가격도 디자인도</span>
            </div>
            <div className="introduceTitle">
              <span className="delay2">틀에서 벗어나다</span>
            </div>
            <div className="introduceDesc">
              <span className="delay3">
                지금 개인 및 기업용 홈페이지를 만들어보세요
              </span>
            </div>
            <motion.div
              ref={videoRef}
              style={{
                scale: scrollYProgress,
              }}
              className="mainVideo"
            >
              <video muted autoPlay loop playsInline>
                <source
                  src="https://framerusercontent.com/assets/nogHw1V0u6oCsesvCplkpAz9U.mp4"
                  type="video/mp4"
                />
              </video>
            </motion.div>
          </div>
          <div className="bottomDim"></div>
        </div>
        <BenefitsComponent />
        <PortfolioComponent />
        <ProcessComponent />
        <PriceComponent />
        <QuestionComponent />
        <StartNowComponent />
      </div>
    </>
  );
};

export default MainContainer;
