import DefaultLayout from "layouts/DefaultLayout";
import MainContainer from "../containers/MainContainer";
import DiscountTimer from "../containers/DiscountTimer";
import { motion, useScroll, useSpring } from "framer-motion";
import "./MainPage.css";

const MainPage = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <DefaultLayout>
      <motion.div className="progress-bar" style={{ scaleX }} />
      <DiscountTimer />
      <MainContainer />
    </DefaultLayout>
  );
};

export default MainPage;
