import "./BenefitsComponent.css";
import { motion } from "framer-motion";
import { ReactComponent as Benefit } from "assets/ribbon.svg";
import { isMobile } from "config";

const BenefitsComponent = () => {
  return (
    <div className="BenefitsComponent">
      <div className="BenefitsContent">
        <div className="stickyContent">
          <div className="title">
            <div className="frames">
              <motion.div
                className="subTitle"
                initial={{ y: "60%" }}
                whileInView={{ y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.6,
                  delay: 0.04,
                }}
              >
                <Benefit className="icon" fill="#ff4747" width="22" />
                benefits
              </motion.div>
            </div>
            <div className="frames">
              <motion.div
                initial={{ y: "100%" }}
                whileInView={{ y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.6,
                  delay: 0.1,
                }}
              >
                홈페이지 제작은
              </motion.div>
            </div>
            <div className="frames">
              <motion.div
                initial={{ y: "100%" }}
                whileInView={{ y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.6,
                  delay: 0.15,
                }}
              >
                왜 에브리페이지일까요?
              </motion.div>
            </div>

            <div className="frames">
              <motion.div
                className="desc"
                initial={{ y: "100%" }}
                whileInView={{ y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.6,
                  delay: 0.2,
                }}
              >
                웹 개발 전문가의 신속한 맞춤 제작
                <br />
                하나부터 열까지 다 해주니까
              </motion.div>
            </div>
          </div>
        </div>
        <div className="scrollContent">
          <motion.div
            className="benefitItem"
            initial={{ x: "-7%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 0.27 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            블록으로 비용을 책정해요
          </motion.div>
          <motion.div
            className="benefitItem"
            initial={{ x: "-7%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 0.27 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            주문 시 5일 이내로 제작해요
          </motion.div>
          <motion.div
            className="benefitItem"
            initial={{ x: "-7%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 0.27 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            무료 수정 기간을 제공해요
          </motion.div>
          <motion.div
            className="benefitItem"
            initial={{ x: "-7%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 0.27 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            웹디자인 트렌드를 반영해요
          </motion.div>
          <motion.div
            className="benefitItem"
            initial={{ x: "-7%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 0.27 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            반응형으로 디자인해요
          </motion.div>
          <motion.div
            className="benefitItem"
            initial={{ x: "-7%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 0.27 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            benefit
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsComponent;
