import { motion } from "framer-motion";
import "./PriceComponent.css";
import { ReactComponent as Price } from "assets/card.svg";
import { ReactComponent as Sqaure } from "assets/square.svg";
import { ReactComponent as Outline } from "assets/outline.svg";
import { ReactComponent as Grid } from "assets/grid.svg";
import { isMobile } from "config";

const PriceComponent = () => {
  return (
    <div className="PriceComponent">
      <div className="title">
        <div className="frames">
          <motion.div
            className="subTitle"
            initial={{ y: "60%" }}
            whileInView={{ y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0,
            }}
          >
            <Price className="icon" fill="#ff4747" width="22" />
            pricing
          </motion.div>
        </div>
        <div className="frames">
          <motion.div
            initial={{ y: "100%" }}
            whileInView={{ y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.1,
            }}
          >
            가격 정책
          </motion.div>
        </div>
        <div className="frames">
          <motion.div
            className="desc"
            initial={{ y: "100%" }}
            whileInView={{ y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.15,
            }}
          >
            섹션 선택에 따른 비용 최적화
            <br />
            품질 대비 가장 저렴한 가격으로 이용하세요
          </motion.div>
        </div>
      </div>
      <div className="priceItems">
        <motion.div
          className="priceItem activeFrame"
          initial={{ x: "113%", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true, amount: isMobile ? 0 : 0.4 }}
          transition={{
            ease: "easeInOut",
            duration: 0.8,
          }}
        >
          <div className="priceImage"></div>
          <div className="priceContent">
            <div className="priceTitle">BASIC</div>
            <div className="priceSubTitle">
              원페이지로 구성된 랜딩페이지입니다.
            </div>
            <div className="priceDesc">
              ✓ 무제한 섹션 추가
              <br />✓ 7일 무료 수정 기간 제공
            </div>
          </div>
        </motion.div>
        <motion.div
          className="priceItem"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, amount: isMobile ? 0 : 0.4 }}
          transition={{
            ease: "easeInOut",
            duration: 0.8,
          }}
        >
          <div className="priceImage"></div>
          <div className="priceContent">
            <div className="priceTitle">STANDARD</div>
            <div className="priceSubTitle">
              여러 페이지로 구성된 기업형 홈페이지입니다.
            </div>
            <div className="priceDesc">
              ✓<br />✓ 1달 무료 수정 기간 제공
            </div>
          </div>
        </motion.div>
        <motion.div
          className="priceItem"
          initial={{ x: "-112%", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true, amount: isMobile ? 0 : 0.4 }}
          transition={{
            ease: "easeInOut",
            duration: 0.8,
          }}
        >
          <div className="priceImage"></div>
          <div className="priceContent">
            <div className="priceTitle">PREMIUM</div>
            <div className="priceSubTitle">
              전체 커스텀 디자인 홈페이지입니다.
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PriceComponent;
