import { motion } from "framer-motion";
import "./PortfolioComponent.css";
import { ReactComponent as Image } from "assets/image.svg";

const PortfolioComponent = () => {
  return (
    <div className="PortfolioComponent">
      <div className="portfolioContent">
        <div className="title">
          <div className="frames">
            <motion.div
              className="subTitle"
              initial={{ y: "60%" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0,
              }}
            >
              <Image className="icon" fill="#1188ff" width="22" />
              portfolio
            </motion.div>
          </div>
          <div className="frames">
            <motion.div
              initial={{ y: "100%" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0.1,
              }}
            >
              에브리페이지에서 만들었어요
            </motion.div>
          </div>
          <div className="frames">
            <motion.div
              className="desc"
              initial={{ y: "100%" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.5,
                delay: 0.25,
              }}
            >
              어떤 홈페이지든 만들 수 있어요
            </motion.div>
          </div>
        </div>
        <div className="gallery">
          <motion.div
            className="galleryItem"
            initial={{ y: "60%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0,
            }}
          ></motion.div>
          <motion.div
            className="galleryItem"
            initial={{ y: "60%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.15,
            }}
          ></motion.div>
          <motion.div
            className="galleryItem"
            initial={{ y: "60%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.3,
            }}
          ></motion.div>
          <motion.div
            className="galleryItem"
            initial={{ y: "60%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.3,
            }}
          ></motion.div>
          <motion.div
            className="galleryItem"
            initial={{ y: "60%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.15,
            }}
          ></motion.div>
          <motion.div
            className="galleryItem"
            initial={{ y: "60%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true, amount: 0.4 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0,
            }}
          ></motion.div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioComponent;
