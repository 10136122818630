import { motion } from "framer-motion";
import "./ProcessComponent.css";
import { ReactComponent as Map } from "assets/map.svg";

const ProcessComponent = () => {
  return (
    <div className="ProcessComponent">
      <div className="processContent">
        <div className="title">
          <div className="frames">
            <motion.div
              className="subTitle"
              initial={{ y: "60%" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0,
              }}
            >
              <Map className="icon" fill="#09cb5a" width="22" />
              process
            </motion.div>
          </div>
          <div className="frames">
            <motion.div
              initial={{ y: "100%" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0.1,
              }}
            >
              제작은 이렇게 진행돼요
            </motion.div>
          </div>
          <div className="frames">
            <motion.div
              className="desc"
              initial={{ y: "100%" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.5,
                delay: 0.25,
              }}
            >
              복잡한 제작 과정을 최대한 간단하게
            </motion.div>
          </div>
        </div>

        <motion.div
          className="processItem zIndex100"
          initial={{ y: "60%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0,
          }}
          style={{ position: "relative", zIndex: 100 }}
        >
          <div className="horizontalLine"></div>
          <div className="itemDescLeft">
            이것은 첫 번째 제작 프로세스입니다.
          </div>
          <div className="itemIndexLeft">01</div>
        </motion.div>

        <motion.div
          className="processItem"
          initial={{ y: "60%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0,
          }}
          style={{ position: "relative", zIndex: 90 }}
        >
          <div className="centerLine"></div>
          <div className="verticalLine"></div>
          <div className="itemDescRight">
            이것은 두 번째 제작 프로세스입니다.
          </div>
          <div className="itemIndexRight">02</div>
        </motion.div>

        <motion.div
          className="processItem"
          initial={{ y: "60%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0,
          }}
          style={{ position: "relative", zIndex: 80 }}
        >
          <div className="centerLine"></div>
          <div className="horizontalLine"></div>
          <div className="itemDescLeft">
            이것은 세 번째 제작 프로세스입니다.
          </div>
          <div className="itemIndexLeft">03</div>
        </motion.div>

        <motion.div
          className="processItem"
          initial={{ y: "60%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0,
          }}
          style={{ position: "relative", zIndex: 70 }}
        >
          <div className="centerLine"></div>
          <div className="verticalLine"></div>
          <div className="itemDescRight">
            이것은 네 번째 제작 프로세스입니다.
          </div>
          <div className="itemIndexRight">04</div>
        </motion.div>
      </div>
    </div>
  );
};

export default ProcessComponent;
