import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import "./DiscountTimer.css";
import { ReactComponent as CloseBtn } from "assets/close-outline.svg";

const DiscountTimer = () => {
  const [timeLeft, setTimeLeft] = useState("");
  const [isHideTimer, setIsHideTimer] = useState(false);

  useEffect(() => {
    const targetDate = new Date("2024-09-30T00:00:00").getTime();

    const formatTimeUnit = (unit: any) => {
      return unit < 10 ? `0${unit}` : unit;
    };

    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const differenceInMilliseconds = targetDate - now;

      if (differenceInMilliseconds <= 0) {
        return "The date has already passed.";
      }

      const seconds = Math.floor((differenceInMilliseconds / 1000) % 60);
      const minutes = Math.floor((differenceInMilliseconds / (1000 * 60)) % 60);
      const hours = Math.floor(
        (differenceInMilliseconds / (1000 * 60 * 60)) % 24
      );
      const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

      return `${days}일 ${formatTimeUnit(hours)}시간 ${formatTimeUnit(
        minutes
      )}분 ${formatTimeUnit(seconds)}초`;
    };

    const updateTimer = () => {
      setTimeLeft(calculateTimeLeft());
    };

    updateTimer(); // Initial call to set the time immediately
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const hideTimer = () => {
    setIsHideTimer(true);
  };

  return (
    <div className={isHideTimer ? "HidingDiscountTimer" : "DiscountTimer"}>
      <div className="timerFrame">
        <div className="text">
          <img
            src="https://emojicdn.elk.sh/%F0%9F%8E%89?style=apple"
            alt="축하"
          ></img>{" "}
          오픈 기념 50% 할인 종료까지
          <motion.div
            whileHover={{ scale: 1.15 }}
            whileTap={{
              scale: 1,
            }}
            className="closeBtn"
            onClick={hideTimer}
          >
            <CloseBtn />
          </motion.div>
          <div className="timer">{timeLeft}</div>{" "}
        </div>
      </div>
    </div>
  );
};

export default DiscountTimer;
