import "./Footer.css";
import { ReactComponent as Logo } from "assets/logo_footer.svg";

const Footer = () => {
  return (
    <footer>
      <div className="footerSection">
        <div className="footerMenu">
          <div>
            <div className="menuTitle">서비스</div>
            <div className="menuSub">홈</div>
            <div className="menuSub">섹션1</div>
            <div className="menuSub">섹션2</div>
            <div className="menuSub">섹션3</div>
          </div>
          <div>
            <div className="menuTitle">문의</div>
            <div className="menuSub">섹션2</div>
            <div className="menuSub">섹션3</div>
          </div>
          <div>
            <div className="menuTitle">지원</div>
            <div className="menuSub">섹션2</div>
            <div className="menuSub">섹션3</div>
          </div>
        </div>
        <div className="footerBuisness">
          <div>
            <Logo height="60" opacity={0.7} className="logoFooter" />
          </div>
          상호명 : 에브리페이지 <span>|</span> 대표 : 최태호 <span>|</span>{" "}
          사업자등록번호 : 105-87-83592 <span>|</span> 통신판매업신고번호 : 제
          2023-서울강남-02377
          <div className="createdBy">☀ created by every.page</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
