import { motion } from "framer-motion";
import "./QuestionComponent.css";
import { ReactComponent as Question } from "assets/chatbubbles.svg";
import { isMobile } from "config";

const QuestionComponent = () => {
  return (
    <div className="QuestionComponent">
      <div className="QuestionContent">
        <div className="title">
          <Question className="icon" fill="black" width="560px" />
          <div className="frames">
            <motion.div
              className="subTitle"
              initial={{ y: "60%" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0,
              }}
            >
              FAQ
            </motion.div>
          </div>
          <div className="frames">
            <motion.div
              initial={{ y: "100%" }}
              whileInView={{ y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0.1,
              }}
            >
              자주 묻는 질문
            </motion.div>
          </div>
        </div>
        <div className="talkFrame">
          <motion.div
            className="question"
            initial={{ x: "-30px", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            1 더하기 1은 무엇인지 아십니까?
          </motion.div>
          <motion.div
            className="answer"
            initial={{ x: "30px", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            첫 번째 질문부터 상당한 난이도의 질문을 주셨는데요. 저희 직원들과
            머리를 싸매며 고심해본 결과, 1 더하기 1의 정답은 창문이라는 결론에
            도달하였습니다. 감사합니다.
          </motion.div>
          <motion.div
            className="question"
            initial={{ x: "-30px", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            내일은 출근을 해야 하는 날로 알고 있는데요. 혹시 몇 시쯤 주무실
            건지가 궁금합니다.
          </motion.div>
          <motion.div
            className="answer"
            initial={{ x: "30px", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            현재 새벽 1시 44분인데요. 출근을 해야 하는 날은 8사 반에는 일어나야
            하기 때문에 2시까지 작업을 합니다. 그래서 이제 누우려고요.
            걱정해주셔서 감사합니다.
          </motion.div>
          <motion.div
            className="question"
            initial={{ x: "-30px", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            이것은 질문입니다.
          </motion.div>
          <motion.div
            className="answer"
            initial={{ x: "30px", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true, amount: isMobile ? 0 : 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
          >
            알고 있습니다.
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default QuestionComponent;
