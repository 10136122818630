import "./Header.css";
import { ReactComponent as Logo } from "assets/logo.svg";
import { isMobile } from "../config";
import { useState, useEffect } from "react";

const Header = () => {
  const [isBorderBottom, setIsBorderBottom] = useState(true);
  const borderOnValue = isMobile ? 0 : 50;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition <= borderOnValue) {
        setIsBorderBottom(true);
        (document.querySelector(".logoFill") as any).innerHTML =
          ".st01{fill:#161616;}";
      } else {
        setIsBorderBottom(false);
        (document.querySelector(".logoFill") as any).innerHTML =
          ".st01{fill:#161616;}";
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [borderOnValue]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <header>
      {!isMobile && (
        <div id="adDiv">
          <div className="topAd">
            <div id="adComment">합리적인 홈페이지 제작, 에브리페이지</div>
            <div className="openOffer">
              <span id="adGoTo">
                1분만에 <span>견적 알아보기</span> →
              </span>
            </div>
          </div>
        </div>
      )}
      <div className={isBorderBottom ? "scrolledStickyDiv" : "stickyDiv"}>
        <div className="topbar">
          <button className="btnScrollToTop" onClick={scrollToTop}>
            <Logo className="logoHeader" height="60" />
          </button>
          <div id={isBorderBottom ? "topbarDiv" : "scrolledTopbarDiv"}>
            <button onClick={() => {}} id="signIn">
              문의하기
            </button>
            <button onClick={() => {}} id="signUp">
              제작신청
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
