import { motion } from "framer-motion";
import "./StartNowComponent.css";
import { isMobile } from "config";

const StartNowComponent = () => {
  return (
    <div className="StartNowComponent">
      <div className="titleFrame">
        <motion.div
          className="title"
          initial={{ y: "100%" }}
          whileInView={{ y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            y: { duration: 0.6 },
          }}
        >
          지금 바로 상담부터 시작해보세요
        </motion.div>
      </div>
      <div className="descFrame">
        <motion.div
          className="desc"
          initial={{ y: "100%" }}
          whileInView={{ y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0.1,
          }}
        >
          연중무휴 24시간 상담 가능, 평균 1시간 이내 응답
        </motion.div>
      </div>
      <button onClick={() => {}} id="offer">
        제작 상담 받기
      </button>
    </div>
  );
};

export default StartNowComponent;
